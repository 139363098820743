import React, { useState, useEffect, useMemo } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import MyDialog from "../components/successPopup.jsx"
import Logo from '../assets/Logo.png';
import axios from 'axios';
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@heroicons/react/solid';

function Registration(params) {

    const [formData, setFormData] = useState({
        I01name: '',
        I02email: '',
        I03phone: '',
        I04role: '',
        II01masjidname: '',
        II02masjidaddress: '',
        II03masjidcity: '',
        II04masjidstate: '',
        II05masjidzip: '',
        II06masjidwebsite: '',
        II07masjidstripeusage: '',
        II08masjidstripehelp: '',
        II09masjidlegalname: '',
        II10masjidein: '',
        II11masjidbankaccount: '',
        II12masjidbankrouting: '',
        II13masjidmailing: '',
        II14masjidcountry: '',
        II15masjidphone: '',
        II16masjidemail: '',
        III01recipientname: '',
        III021recipientemail: '',
        III03recipientphone: '',
        III04recipientstreetaddress: '',
        III05recipientcity: '',
        III06recipientstate: '',
        III07recipientzip: '',
        IV01termsandconditions: ''
    });
    

    const usStates = [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' }
    ];

    const canadianProvinces = [
        { value: 'AB', label: 'Alberta' },
        { value: 'BC', label: 'British Columbia' },
        { value: 'MB', label: 'Manitoba' },
        { value: 'NB', label: 'New Brunswick' },
        { value: 'NL', label: 'Newfoundland and Labrador' },
        { value: 'NS', label: 'Nova Scotia' },
        { value: 'ON', label: 'Ontario' },
        { value: 'PE', label: 'Prince Edward Island' },
        { value: 'QC', label: 'Quebec' },
        { value: 'SK', label: 'Saskatchewan' },
        { value: 'NT', label: 'Northwest Territories' },
        { value: 'NU', label: 'Nunavut' },
        { value: 'YT', label: 'Yukon' }
    ];

    const getStateOptions = () => {
        if (formData.II14masjidcountry === 'US') {
            return usStates;
        } else if (formData.II14masjidcountry === 'CA') {
            return canadianProvinces;
        }
        return [];
    };

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const steps = [
        {
            title: 'Step 1',
            content: 'About You'
        },
        {
            title: 'Step 2',
            content: 'About Masjid'
        },
        {
            title: 'Step 3',
            content: 'Payment Processing and Bank Informatino'
        },
        {
            title: 'Step 4',
            content: 'Mailing Address'
        },
        {
            title: 'Step 5',
            content: 'Terms of Service'
        },
    ];


    const [emailError, setEmailError] = useState(false);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setFormData(prevState => ({
            ...prevState,
            [name]: fieldValue
        }));

        if (name === 'email') {
            setEmailError(!isValidEmail(value));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(JSON.stringify(formData));
            const response = await fetch('https://formspree.io/f/mgvewpwy', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
                setIsSubmitted(true);
                setShowModal(true);

                console.log("GO")

                // Check if II08masjidstripehelp is "yes"
                if (formData.II08masjidstripehelp === "no") {
                    console.log("NO")
                    await sendStripeSetupEmail();
                }
            } else {
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const sendStripeSetupEmail = async () => {
        try {
            const stripeSetupData = {
                userName: formData.I01name,
                userEmail: formData.I02email,
                userPhone: formData.I03phone,
                orgName: formData.II01masjidname,
                orgEIN: formData.II10masjidein,
                orgStreetAddress: formData.II02masjidaddress,
                orgCity: formData.II03masjidcity,
                orgState: formData.II04masjidstate,
                orgZip: formData.II05masjidzip,
                orgCountry: formData.II14masjidcountry,
                orgWebsite: formData.II06masjidwebsite,
                orgPhone: formData.II15masjidphone,
                orgEmail: formData.II16masjidemail
            };

            console.log(stripeSetupData);

            const response = await axios.post('https://donate.givemasjid.com/email/stripe', stripeSetupData);

            if (response.status === 200) {
                console.log('Stripe setup email sent successfully');
            } else {
                console.error('Failed to send Stripe setup email');
            }
        } catch (error) {
            console.error('Error sending Stripe setup email:', error);
        }
    };
    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };


    // Show top of page on load
    useEffect(() => {

        window.scrollTo(0, 0);

    }, []);

    // Step Handler
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };
    
    // Tooltip
    const Tooltip = ({ children, text, show }) => {
        return (
            <div className="relative group">
                {children}
                {show && (
                    <div className="absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none max-w-xs w-max">
                        {text}
                        <svg className="absolute text-gray-900 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" xmlSpace="preserve">
                            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
                        </svg>
                    </div>
                )}
            </div>
        );
    };

    // Form Dialog
    return (
        <>
            <MyDialog showState={showModal} setShowState={setShowModal} source="registration" />
            <div className="min-h-screen flex overflow-x-hidden">
                <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-4xl lg:w-4/5">
                        <div>
                            <a href="/">
                                <img
                                    className="h-7 w-auto"
                                    src={Logo}
                                    alt="GiveMasjid"
                                />
                            </a>
                            <h2 className="mt-6 text-2xl font-extrabold text-gray-900">New Account <span className="text-indigo-600">Registration</span> </h2>
                            <p className="mt-2 text-base text-gray-600">
                                Ready to started? We're excited to begin working with you, inshallah. In order to create your account, we'll need more information about yourself and the masjid you work with.
                            </p>

                            <div className="w-full border-t my-4 border-gray-300" />

                            <form onSubmit={handleSubmit} className="space-y-6">
                                {/* Section 1: About You */}
                                <div>
                                    {currentStep === 1 && (
                                        <div>
                                            <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Tell Us About Yourself</h3>

                                            <div className="mt-5">
                                                <label htmlFor="I01name" className="block text-base font-medium text-gray-700">
                                                    Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="I01name"
                                                        name="I01name"
                                                        type="text"
                                                        autoComplete="name"
                                                        value={formData["I01name"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="I02email" className="block text-base font-medium text-gray-700">
                                                    Email Address
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="I02email"
                                                        name="I02email"
                                                        type="email"
                                                        autoComplete="email"
                                                        value={formData["I02email"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="I03phone" className="block text-base font-medium text-gray-700">
                                                    Phone Number
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="I03phone"
                                                        name="I03phone"
                                                        type="tel"
                                                        autoComplete="tel"
                                                        value={formData["I03phone"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="I04role" className="block text-base font-medium text-gray-700">
                                                    Your Role in the Masjid
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="I04role"
                                                        name="I04role"
                                                        type="text"
                                                        autoComplete="organization-title"
                                                        value={formData["I04role"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>


                                {/* Section 2: About The Masjid */}
                                <div>
                                    {currentStep === 2 && (
                                        <div>
                                            <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Tell Us About Your Masjid</h3>

                                            <div className="mt-5">
                                                <label htmlFor="II01masjidname" className="block text-base font-medium text-gray-700">
                                                    Masjid Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="II01masjidname"
                                                        name="II01masjidname"
                                                        type="text"
                                                        autoComplete="name"
                                                        value={formData["II01masjidname"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="II10masjidein" className="block text-base font-medium text-gray-700">
                                                    EIN / Registration Number 
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="II10masjidein"
                                                        name="II10masjidein"
                                                        type="text"
                                                        value={formData["II10masjidein"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="II14masjidcountry" className="block text-base font-medium text-gray-700">
                                                    Country
                                                </label>
                                                <div className="mt-1">
                                                    <select
                                                        id="II14masjidcountry"
                                                        name="II14masjidcountry"
                                                        autoComplete="country"
                                                        value={formData["II14masjidcountry"]}
                                                        onChange={handleInputChange}
                                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    >
                                                        <option value="">Select a country</option>
                                                        <option value="US">United States</option>
                                                        <option value="CA">Canada</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="II02masjidaddress" className="block text-base font-medium text-gray-700">
                                                    Street Address
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="II02masjidaddress"
                                                        name="II02masjidaddress"
                                                        type="text"
                                                        autoComplete="street-address"
                                                        value={formData["II02masjidaddress"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="II03masjidcity" className="block text-base font-medium text-gray-700">
                                                    City
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="II03masjidcity"
                                                        name="II03masjidcity"
                                                        type="text"
                                                        autoComplete="address-level2"
                                                        value={formData["II03masjidcity"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                            <div className="mt-5">
                                <label htmlFor="II04masjidstate" className="block text-base font-medium text-gray-700">
                                    State / Province
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="II04masjidstate"
                                        name="II04masjidstate"
                                        autoComplete="address-level1"
                                        value={formData["II04masjidstate"]}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                        disabled={!formData["II14masjidcountry"]}
                                    >
                                        <option value="">Select a state/province</option>
                                        {getStateOptions().map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="II05masjidzip" className="block text-base font-medium text-gray-700">
                                                    Postal Code
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="II05masjidzip"
                                                        name="II05masjidzip"
                                                        type="text"
                                                        autoComplete="postal-code"
                                                        value={formData["II05masjidzip"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="II06masjidwebsite" className="block text-base font-medium text-gray-700">
                                                    Website (if available)
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="II06masjidwebsite"
                                                        name="II06masjidwebsite"
                                                        type="text"
                                                        autoComplete="url"
                                                        value={formData["II06masjidwebsite"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="II15masjidphone" className="block text-base font-medium text-gray-700">
                                                    Phone (if available)
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="II15masjidphone"
                                                        name="II15masjidphone"
                                                        type="text"
                                                        autoComplete="tel"
                                                        value={formData["II15masjidphone"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label htmlFor="II16masjidemail" className="block text-base font-medium text-gray-700">
                                                    Email (if available)
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="II16masjidemail"
                                                        name="II16masjidemail"
                                                        type="email"
                                                        autoComplete="email"
                                                        value={formData["II16masjidemail"]}
                                                        onChange={handleInputChange}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>

                                {/* Section 3: Stripe Setup */}
                                <div>
                                    {currentStep === 3 && (
                                        <div>
                                            <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Payment Processing Setup</h3>

                                            <div className="mt-5">
                                                <label className="block text-base font-medium text-gray-700">
                                                    Does your masjid currently use Stripe for credit card processing?
                                                </label>
                                                <div className="mt-4">
                                                    <div className="flex items-center mb-3">
                                                        <input
                                                            id="II07masjidstripeusage-yes"
                                                            name="II07masjidstripeusage"
                                                            type="radio"
                                                            value="yes"
                                                            checked={formData["II07masjidstripeusage"] === "yes"}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                        />
                                                        <label htmlFor="II07masjidstripeusage-yes" className="ml-3 text-base font-medium text-gray-700">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center mb-3">
                                                        <input
                                                            id="II07masjidstripeusage-no"
                                                            name="II07masjidstripeusage"
                                                            type="radio"
                                                            value="no"
                                                            checked={formData["II07masjidstripeusage"] === "no"}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                        />
                                                        <label htmlFor="II07masjidstripeusage-no" className="ml-3 text-base font-medium text-gray-700">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <input
                                                            id="II07masjidstripeusage-unsure"
                                                            name="II07masjidstripeusage"
                                                            type="radio"
                                                            value="unsure"
                                                            checked={formData["II07masjidstripeusage"] === "unsure"}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                        />
                                                        <label htmlFor="II07masjidstripeusage-unsure" className="ml-3 text-base font-medium text-gray-700">
                                                            Unsure
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Conditional rendering based on the user's selection */}
                                            {/* If the user selects 'Yes', display the message */}
                                            {formData["II07masjidstripeusage"] === "yes" && (
                                                <div className="mt-5">
                                                    <p className="text-base text-violet-500 font-base italic">
                                                        Great! We will email you once you complete this form some instructions on how to link your masjid's Stripe account to GiveMasjid.
                                                    </p>
                                                </div>
                                            )}

                                            {/* If the user selects 'No' or 'Unsure', display the additional question */}
                                            {(formData["II07masjidstripeusage"] === "no" || formData["II07masjidstripeusage"] === "unsure") && (
                                                <div className="mt-5">
                                                    <label className="block text-base font-medium text-gray-700">
                                                        GiveMasjid uses Stripe as its credit card processor. Would you like our help setting up a Stripe account?
                                                    </label>
                                                    <div className="mt-4">
                                                        <div className="flex items-center mb-3">
                                                            <input
                                                                id="II08masjidstripehelp-yes"
                                                                name="II08masjidstripehelp"
                                                                type="radio"
                                                                value="yes"
                                                                checked={formData["II08masjidstripehelp"] === "yes"}
                                                                onChange={handleInputChange}
                                                                className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                            />
                                                            <label htmlFor="II08masjidstripehelp-yes" className="ml-3 text-base font-medium text-gray-700">
                                                                Yes. Please handle this for us.
                                                            </label>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <input
                                                                id="II08masjidstripehelp-no"
                                                                name="II08masjidstripehelp"
                                                                type="radio"
                                                                value="no"
                                                                checked={formData["II08masjidstripehelp"] === "no"}
                                                                onChange={handleInputChange}
                                                                className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                            />
                                                            <label htmlFor="II08masjidstripehelp-no" className="ml-3 text-base font-medium text-gray-700">
                                                                No. We will create our own Stripe Account.
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {formData["II08masjidstripehelp"] === "no" && (
                                                        <div className="mt-5">
                                                            <p className="text-base text-violet-500 italic font-base">
                                                                Great! Please continue the registration process. We will send you some additional information on configuring your Stripe account after you've completed this form.
                                                            </p>
                                                        </div>
                                                    )}
                                                    {formData["II08masjidstripehelp"] === "yes" && (
                                                        <div className="mt-5">
                                                            <div className="w-full border-t my-4 border-gray-300" />

                                                            <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Bank Payment Information</h3>

                                                            {/* Additional fields for EIN/BN, Bank Account Number, and Bank Routing Number */}
                                                            <div className="mt-5">
                                                                <label htmlFor="II09masjidlegalname" className="block text-base font-medium text-gray-700">
                                                                    Legal Organization Name
                                                                </label>

                                                                <div className="mt-1">
                                                                    <input
                                                                        id="II09masjidlegalname"
                                                                        name="II09masjidlegalname"
                                                                        type="text"
                                                                        value={formData["II09masjidlegalname"]}
                                                                        onChange={handleInputChange}
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="mt-5">
                                                                <label htmlFor="II11masjidbankaccount" className="block text-base font-medium text-gray-700">
                                                                    Bank Account Number
                                                                </label>
                                                                <div className="mt-1">
                                                                    <input
                                                                        id="II11masjidbankaccount"
                                                                        name="II11masjidbankaccount"
                                                                        type="text"
                                                                        value={formData["II11masjidbankaccount"]}
                                                                        onChange={handleInputChange}
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mt-5">
                                                                <label htmlFor="II12masjidbankrouting" className="block text-base font-medium text-gray-700">
                                                                    Bank Routing Number
                                                                </label>
                                                                <div className="mt-1">
                                                                    <input
                                                                        id="II12masjidbankrouting"
                                                                        name="II12masjidbankrouting"
                                                                        type="text"
                                                                        value={formData["II12masjidbankrouting"]}
                                                                        onChange={handleInputChange}
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                </div>


                                {/* Section 4: Mailing Address */}
                                <div>
                                    {currentStep === 4 && (
                                        <div>
                                            <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Mailing Address</h3>

                                            <div className="mt-5">
                                                <label className="block text-base font-medium text-gray-700">
                                                    What's the best address to mail our posters and flyers to?
                                                </label>
                                                <div className="mt-4">
                                                    <div className="flex items-center mb-3">
                                                        <input
                                                            id="II13masjidmailing-masjid"
                                                            name="II13masjidmailing"
                                                            type="radio"
                                                            value="masjid"
                                                            checked={formData["II13masjidmailing"] === "masjid"}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                        />
                                                        <label htmlFor="II13masjidmailing-masjid" className="ml-3 text-base font-medium text-gray-700">
                                                            Masjid Address
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center mb-3">
                                                        <input
                                                            id="II13masjidmailing-your"
                                                            name="II13masjidmailing"
                                                            type="radio"
                                                            value="your"
                                                            checked={formData["II13masjidmailing"] === "your"}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                        />
                                                        <label htmlFor="II13masjidmailing-your" className="ml-3 text-base font-medium text-gray-700">
                                                            Your Address
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <input
                                                            id="II13masjidmailing-other"
                                                            name="II13masjidmailing"
                                                            type="radio"
                                                            value="other"
                                                            checked={formData["II13masjidmailing"] === "other"}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                        />
                                                        <label htmlFor="II13masjidmailing-other" className="ml-3 text-base font-medium text-gray-700">
                                                            Other Address
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {formData["II13masjidmailing"] === "masjid" && (
                                                <div className="mt-5">
                                                    <p className="text-base text-violet-500 italic font-base">
                                                        Great! After our onboarding meeting, we will mail our materials to the masjid at the address you provided earlier.
                                                    </p>
                                                </div>
                                            )}

                                            {formData["II13masjidmailing"] === "other" && (
                                                <div className="mt-5">
                                                    <div className="mt-5">
                                                        <label htmlFor="III01recipientname" className="block text-base font-medium text-gray-700">
                                                            Recipient Name
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                id="III01recipientname"
                                                                name="III01recipientname"
                                                                type="text"
                                                                value={formData["III01recipientname"]}
                                                                onChange={handleInputChange}
                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-5">
                                                        <label htmlFor="III021recipientemail" className="block text-base font-medium text-gray-700">
                                                            Recipient Email
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                id="III021recipientemail"
                                                                name="III021recipientemail"
                                                                type="email"
                                                                value={formData["III021recipientemail"]}
                                                                onChange={handleInputChange}
                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-5">
                                                        <label htmlFor="III03recipientphone" className="block text-base font-medium text-gray-700">
                                                            Recipient Phone
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                id="III03recipientphone"
                                                                name="III03recipientphone"
                                                                type="tel"
                                                                value={formData["III03recipientphone"]}
                                                                onChange={handleInputChange}
                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {(formData["II13masjidmailing"] === "your" || formData["II13masjidmailing"] === "other") && (
                                                <div className="mt-5">
                                                    <div className="mt-5">
                                                        <label htmlFor="III04recipientstreetaddress" className="block text-base font-medium text-gray-700">
                                                            Street Address
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                id="III04recipientstreetaddress"
                                                                name="III04recipientstreetaddress"
                                                                type="text"
                                                                value={formData["III04recipientstreetaddress"]}
                                                                onChange={handleInputChange}
                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-5">
                                                        <label htmlFor="III05recipientcity" className="block text-base font-medium text-gray-700">
                                                            City
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                id="III05recipientcity"
                                                                name="III05recipientcity"
                                                                type="text"
                                                                value={formData["III05recipientcity"]}
                                                                onChange={handleInputChange}
                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-5">
                                                        <label htmlFor="III06recipientstate" className="block text-base font-medium text-gray-700">
                                                            State / Province
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                id="III06recipientstate"
                                                                name="III06recipientstate"
                                                                type="text"
                                                                value={formData["III06recipientstate"]}
                                                                onChange={handleInputChange}
                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-5">
                                                        <label htmlFor="III07recipientzip" className="block text-base font-medium text-gray-700">
                                                            Zip Code
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                id="III07recipientzip"
                                                                name="III07recipientzip"
                                                                type="text"
                                                                value={formData["III07recipientzip"]}
                                                                onChange={handleInputChange}
                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                </div>

                                {/* Section 5: Terms and Conditions */}
                                <div>
                                    {currentStep === 5 && (
                                        <div>
                                            <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Terms and Conditions</h3>

                                            <div className="mt-5">
                                                <div className="flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input
                                                            id="IV01termsandconditions"
                                                            name="IV01termsandconditions"
                                                            type="checkbox"
                                                            checked={formData["IV01termsandconditions"]}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-base">
                                                        <label htmlFor="IV01termsandconditions" className="font-medium text-gray-700">
                                                            I agree to the{" "}
                                                            <a href="https://sadaqahqr-web.s3.amazonaws.com/GiveMasjid_Terms_And_Conditions.pdf" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
                                                                Terms and Conditions
                                                            </a>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>

                                {/* Footer */}
                                <div className="w-full border-t my-4 border-gray-300" />



                                {/* Step Control */}
                                <div className="mt-8 flex items-center justify-between">
                                    {/* Previous Button */}
                                    {currentStep > 1 && (
                                        <button
                                            type="button"
                                            onClick={handlePrevious}
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                            Previous
                                        </button>
                                    )}

                                    {/* Page Counter */}
                                    <div className="text-sm text-gray-700">
                                        Page {currentStep} of {steps.length}
                                    </div>

                                    {/* Submit Button */}
                                    {currentStep === steps.length && (
                                        <Tooltip
                                            text="Please accept the terms and conditions."
                                            show={!formData["IV01termsandconditions"]}
                                        >
                                            <button
                                                type="submit"
                                                disabled={!formData["IV01termsandconditions"]}
                                                className={`inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white w-full sm:w-auto ${!formData["IV01termsandconditions"]
                                                    ? "bg-gray-300 cursor-not-allowed"
                                                    : "bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                                                    }`}
                                            >
                                                <CheckIcon className="lg:mx-2 h-6 w-6" aria-hidden="true" />
                                                <span className="hidden lg:inline">Submit Registration</span>
                                                <span className="lg:hidden">Submit</span>
                                            </button>
                                        </Tooltip>
                                    )}

                                    {/* Next Button */}
                                    {currentStep < steps.length && currentStep !== steps.length && (
                                        <button
                                            onClick={handleNext}
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Next
                                            <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                                        </button>
                                    )}
                                </div>

                            </form>


                        </div>

                    </div>
                </div>
                <div className="w-0 lg:w-3/5 flex-shrink-0">
                    <img
                        className="w-full h-screen object-cover"
                        src="https://images.unsplash.com/photo-1527838700789-f9b453e73655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2540&q=80"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}

// Export Component
export default Registration;