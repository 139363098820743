import React, { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from "react-router-dom";
import MyDialog from "../components/successPopup.jsx"
import Logo from '../assets/Logo.png';
import axios from 'axios';
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@heroicons/react/solid';


function StripeSuccess(params) {

    return (
        <>
            <div className="min-h-screen flex overflow-x-hidden">
                <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-4xl lg:w-4/5">
                        <div>
                            <a href="/">
                                <img
                                    className="h-7 w-auto"
                                    src={Logo}
                                    alt="GiveMasjid"
                                />
                            </a>
                            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                                Registration <span className="text-indigo-600">Complete</span>!
                            </h2>
                            <p className="mt-4 text-lg text-gray-600">
                                Thanks for completing the registration process. Inshallah, we'll be in touch shortly once your account is ready. In the meantime, feel free to email us at <a href="mailto:support@givemasjid.com" className="text-indigo-600">support@givemasjid.com</a> if you have any questions. We look forward to supporting your masjid in its future success!
                            </p>
    
                            <div className="mt-8">
                                <a
                                    href="/"
                                    className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                                >
                                    Return to GiveMasjid
                                    <ArrowRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-0 lg:w-3/5 flex-shrink-0">
                    <img
                        className="w-full h-screen object-cover"
                        src="https://images.unsplash.com/photo-1527838700789-f9b453e73655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2540&q=80"
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}


// Export Component
export default StripeSuccess;