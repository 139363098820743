// src/components/TrialButton.jsx
import React from 'react';
import { Link } from 'react-router-dom';
// import { GiftIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMosque } from '@fortawesome/free-solid-svg-icons';

const TrialButton = ({ isVisible }) => {
  return (
    <Link
      to="/request-trial"
      className={`fixed bottom-4 right-4 bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 z-50 flex items-center transition-opacity ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } duration-1000 ease-in-out`}
    >
      <FontAwesomeIcon icon={faMosque} className="text-lg mr-2" />

      <span>Try GiveMasjid <span className="text-sm italic text-gray-300">for free</span></span>
    </Link>
  );
};

export default TrialButton;