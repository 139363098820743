/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import QRCode from '../assets/QRCode.png'; // Make sure to add this QR code image


// Routing
import { useLocation, useNavigate } from "react-router-dom";

export default function Modal({ showState, setShowState, source = 'trial' }) {
  // Modal source
  const isTrial = source === 'trial';

  const cancelButtonRef = useRef(null)
  const navigate = useNavigate()

  const handleClose = () => {
    setShowState(false)
    navigate('/')
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showState && !event.target.closest('.modal-content')) {
        handleClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showState])

  return (
    <Transition.Root show={showState} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowState}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-gray-900">
                      Submission Received
                    </Dialog.Title>
                    <div className="mt-2">
                      {isTrial ? (
                        <>
                          <p className="text-sm text-gray-500 mb-3">
                            We'll be in touch soon, inshallah! In the meantime, why not check out the live demo?
                          </p>
                          <hr />
                          <h2 className="text-medium font-medium text-gray-900 mb-4 mt-4">GiveMasjid Demo</h2>
                          <p className="text-sm mb-3 mt-3 text-gray-500">
                            Click <a href="https://donate.givemasjid.com/demo" className="text-indigo-600 hover:text-indigo-800 font-semibold" target="_blank" rel="noopener noreferrer">this link</a> to visit the donation page directly.
                          </p>
                          <p className="text-sm mb-4 text-gray-500">Or scan the below code to try on mobile.</p>
                          <img
                            className="w-48 h-48 mx-auto mb-6"
                            src={QRCode}
                            alt="QR Code"
                          />
                        </>
                      ) : (
                        <p className="text-sm text-gray-500 mb-3">
                          We'll be in touch soon, inshallah. Look to your email for more information in the days ahead.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={() => {navigate("/#"); }}
                  >
                    Return to GiveMasjid Website
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}