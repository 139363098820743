import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';

import { TrendingUpIcon } from '@heroicons/react/outline';
import useLiveStats from './useLiveStats';


export default function Banner() {
  const [showBanner, setShowBanner] = useState(true);
  const liveStats = useLiveStats();

  const formatTotalAmountToNearestFloor25k = (totalAmount) => {
    const roundedAmount = Math.floor(totalAmount / 25000) * 25000;
    return roundedAmount.toLocaleString('en-US');
  };

  const formatDonationCountToNearestFloor250 = (donationCount) => {
    const roundedCount = Math.floor(donationCount / 250) * 250;
    return roundedCount.toLocaleString('en-US');
  };

  const dismissBanner = () => {
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="relative bg-cyan-900">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="hidden lg:inline">
                  Alhamdulillah, we've helped masjids collect over <b>${formatTotalAmountToNearestFloor25k(liveStats.totalAmount)}</b> in charity and processed over <b>{formatDonationCountToNearestFloor250(liveStats.donationCount)}</b> donations... and <i>counting!</i>
                </span>
                <span className="inline sm:ml-2 sm:inline-block lg:hidden text-sm">
                  Over <b>${formatTotalAmountToNearestFloor25k(liveStats.totalAmount)}</b> in donations collected to date!
                </span>
              </p>
            </div>
            <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
              <HashLink smooth to="#stats"
                className="flex p-2 rounded-md hover:bg-cyan-800 focus:outline-none focus:ring-2 focus:ring-white"
                onClick={dismissBanner}
              >
                <span className="sr-only">Dismiss</span>
                <TrendingUpIcon className="h-6 w-6 text-white text-sm" aria-hidden="true" />
              </HashLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
